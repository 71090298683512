import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import { graphql } from "gatsby"
import DietLayout from "../components/DietLayout"

export const query = graphql`
  {
    file(name: { eq: "diet1" }) {
      base
      publicURL
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

var planSport = {
  title: "Sport",
  menu: [
    {
      name: "Śniadanie",
      hour: "7:00",
      description: "Zielony omlet z łososiem wędzonym i warzywami",
    },
    {
      name: "Drugie Śniadanie",
      hour: "10:00",
      description: "Bliny gryczane z twarożkiem koperkowo-ogórkowym",
    },
    {
      name: "Obiad",
      hour: "13:00",
      description:
        "Pieczone piersi z kurczaka z pesto, pomidorami i mozzarellą, kasza bulgur i surówka napoleońska",
    },
    {
      name: "Podwieczorek",
      hour: "17:00",
      description: "Fit sernik Pinacolada",
    },
    {
      name: "Kolacja",
      hour: "19:30",
      description:
        "Sałatka z mango, awokado i fetą w sosie miodowo musztardowym z grzanką",
    },
  ],
}

const PlanSportPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Plan Sport" />
      <SmallHeroSection />
      <DecorativeSection />

      <DietLayout diet={planSport} image={data.file}>
        Dieta skomponowana z myślą o osobach uprawiających sport zawodowo lub
        amatorsko. Urozmaicony, pełnowartościowy i kolorowy jadłospis ułatwi
        życie i wzniesie Cię na wyższy poziom sportowy!
        <br />
        Dziś nieodłącznym elementem osiąganych wyników jest także żywienie,
        które ma ogromny wpływ na odpowiednie funkcjonowanie organizmu.
        <br />
        Jeśli zależy Ci zarówno na wynikach, zdrowiu, jak i dobrym samopoczuciu
        ta dieta jest dla Ciebie!
      </DietLayout>
    </Layout>
  )
}

export default PlanSportPage
